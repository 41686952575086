import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Carousel.css';

const Carousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const intervalRef = useRef(null);

  // Función para reiniciar el intervalo
  const resetInterval = useCallback(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
  }, [images.length]);

  // Función para manejar el cambio de imagen
  const changeImage = (index) => {
    setCurrentImageIndex((prevIndex) => (prevIndex + index + images.length) % images.length);
    resetInterval(); // Reinicia el intervalo
  };

  // Configurar y limpiar el intervalo
  useEffect(() => {
    resetInterval(); // Iniciar el intervalo

    return () => clearInterval(intervalRef.current); // Limpiar al desmontar
  }, [resetInterval]);

  return (
    <div className="carousel">
      <div className="carousel-image-container">
        <img src={images[currentImageIndex]} alt={`Slide ${currentImageIndex + 1}`} />
      </div>
      <button className="carousel-arrow left-arrow" onClick={() => changeImage(-1)}>
        &#10094;
      </button>
      <button className="carousel-arrow right-arrow" onClick={() => changeImage(1)}>
        &#10095;
      </button>
      <div className="carousel-dots">
        {images.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentImageIndex ? 'active' : ''}`}
            onClick={() => {
              setCurrentImageIndex(index);
              resetInterval(); // Reinicia el intervalo al hacer clic en un punto
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
